/**
 * @generated SignedSource<<bebafb021ebe0d638dffad86e50e4728>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SurveyAbandonedInput = {
  studyId: string;
};
export type SurveyFeedbackModalAbandonedMutation$variables = {
  input: SurveyAbandonedInput;
};
export type SurveyFeedbackModalAbandonedMutation$data = {
  readonly surveyAbandoned: string | null;
};
export type SurveyFeedbackModalAbandonedMutation = {
  variables: SurveyFeedbackModalAbandonedMutation$variables;
  response: SurveyFeedbackModalAbandonedMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "surveyAbandoned",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SurveyFeedbackModalAbandonedMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SurveyFeedbackModalAbandonedMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4ed005f6478a3251fa98b531a28cd502",
    "id": null,
    "metadata": {},
    "name": "SurveyFeedbackModalAbandonedMutation",
    "operationKind": "mutation",
    "text": "mutation SurveyFeedbackModalAbandonedMutation(\n  $input: SurveyAbandonedInput!\n) {\n  surveyAbandoned(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "fdbae400eb035a6fac4939b382d57922";

export default node;
