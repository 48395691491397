/**
 * @generated SignedSource<<309552afcdb5422b791bf8bdce337a1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SurveyFeedback = "CUSTOM" | "NOT_MOBILE_COMPATIBLE" | "NOT_MOBILE_COMPATIPLE" | "NOT_MOBILE_OPTIMISED" | "SCAM" | "STUCK" | "TOO_LONG" | "WRONG_LANGUAGE";
export type SurveyFeedbackInput = {
  custom?: string | null;
  feedback: SurveyFeedback;
  studyId?: string | null;
};
export type SurveyFeedbackModalMutation$variables = {
  input: SurveyFeedbackInput;
};
export type SurveyFeedbackModalMutation$data = {
  readonly surveyFeedback: string | null;
};
export type SurveyFeedbackModalMutation = {
  variables: SurveyFeedbackModalMutation$variables;
  response: SurveyFeedbackModalMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "surveyFeedback",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SurveyFeedbackModalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SurveyFeedbackModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b71dd779e76cbfcdce056035a6953ebe",
    "id": null,
    "metadata": {},
    "name": "SurveyFeedbackModalMutation",
    "operationKind": "mutation",
    "text": "mutation SurveyFeedbackModalMutation(\n  $input: SurveyFeedbackInput!\n) {\n  surveyFeedback(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "073fb9bd72f631d6caa83161e8e3d39f";

export default node;
