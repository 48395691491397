import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import React, { useState } from 'react';
import { graphql } from 'react-relay';
import { useMutation } from 'react-relay/hooks';
import classes from './SurveyFeedbackModal.module.scss';
import { useAlert } from 'react-alert';
import { alertMessageTimeout as messageTimeout } from '../../molecules/AlertMessage/alertMessageTimeout';

import Overlay from '../../atoms/Overlay/Overlay';
import Button from '../../atoms/Button/Button';
import SendOtherIcon from '../../assets/rightarrow.svg';
import Input from '../../atoms/TextInput/Input';
import { useModalContext } from '../../templates/ModalProvider/ModalProvider';
import { PropSurveyItem } from '../../molecules/SurveyItem/SurveyItem';
import useTranslate from '../../../hooks/useTranslate';
import Txt from '../../atoms/Txt/Txt';
import {
  SurveyFeedback,
  SurveyFeedbackModalMutation,
} from './__generated__/SurveyFeedbackModalMutation.graphql';

import { SurveyFeedbackModalAbandonedMutation } from './__generated__/SurveyFeedbackModalAbandonedMutation.graphql';

const surveyFeedbackMutation = graphql`
  mutation SurveyFeedbackModalMutation($input: SurveyFeedbackInput!) {
    surveyFeedback(input: $input)
  }
`;
const surveyAbandonedMutation = graphql`
  mutation SurveyFeedbackModalAbandonedMutation($input: SurveyAbandonedInput!) {
    surveyAbandoned(input: $input)
  }
`;

dayjs.extend(calendar);

const MESSAGE_COMPLETE =
  'Thank you. We use your feedback to improve your experience with our survey partners.';

export const REASON_ENUM = {
  TOO_LONG: 'TOO_LONG',
  STUCK: 'STUCK',
  NOT_MOBILE_OPTIMISED: 'NOT_MOBILE_OPTIMISED',
  NOT_MOBILE_COMPATIBLE: 'NOT_MOBILE_COMPATIBLE',
  SCAM: 'SCAM',
  WRONG_LANGUAGE: 'WRONG_LANGUAGE',
  CUSTOM: 'CUSTOM',
  CUSTOM_EMPTY: 'CUSTOM_EMPTY',
};

const MESSAGE_END = '';

export const showReasonDetail = (reasonType: string) => {
  switch (reasonType) {
    case REASON_ENUM.TOO_LONG:
      return {
        description: '⏰ The survey was too long',
        message: MESSAGE_COMPLETE,
      };
    case REASON_ENUM.STUCK:
      return {
        description: '😵‍💫 I got stuck',
        message: MESSAGE_COMPLETE,
      };
    case REASON_ENUM.NOT_MOBILE_OPTIMISED:
      return {
        description: `📲 It wasn't great on mobile ${MESSAGE_END}`,
        message: MESSAGE_COMPLETE,
      };
    case REASON_ENUM.NOT_MOBILE_COMPATIBLE:
      return {
        description: `😞 It didn't work on mobile ${MESSAGE_END}`,
        message: MESSAGE_COMPLETE,
      };
    case REASON_ENUM.SCAM:
      return {
        description: '👿 It looked suspicious',
        message: MESSAGE_COMPLETE,
      };
    case REASON_ENUM.WRONG_LANGUAGE:
      return {
        description: '🌐 It was in the wrong language',
        message: MESSAGE_COMPLETE,
      };
    case REASON_ENUM.CUSTOM:
      return {
        description: 'Other reasons',
        message: MESSAGE_COMPLETE,
      };
    default:
      return {
        description: '',
        message: '',
      };
  }
};

export interface Props {
  defaultModalProps?: any;
}

export interface ContentProps {
  modalProps: any;
  onClose: (report: string) => void;
}

export const useLastPending = () => {
  const lastPending = localStorage.getItem('lastPending');
  return lastPending;
};

export const FeedbackButtons: React.FC<ContentProps> = ({ modalProps, onClose }) => {
  const [showOtherComment, setShowOtherComment] = useState(false);
  const [otherComment, setOtherComment] = useState('');
  const [sendSurveyFeedback] = useMutation<SurveyFeedbackModalMutation>(surveyFeedbackMutation);
  const translate = useTranslate();

  const handleOtherChange = (e: any) => {
    setOtherComment(e.target.value);
    if (e.target.value) {
      setShowOtherComment(true);
      return;
    }
    setShowOtherComment(false);
  };

  const handleOtherFocus = () => {
    setShowOtherComment(true);
  };

  const showOtherButton = () => {
    if (!showOtherComment) {
      return classes.inactive;
    }
    if (otherComment.length > 0) {
      return classes.active;
    }
    return classes.focus;
  };
  const handleOnFeedbackPress = (feedback: SurveyFeedback) => () => {
    if (feedback === REASON_ENUM.CUSTOM && otherComment.length === 0) {
      return;
    }
    sendSurveyFeedback({
      variables: {
        input: {
          studyId: modalProps.studyId || 'missingFromSite',
          feedback,
          custom: otherComment,
        },
      },
    });
    onClose(feedback);
  };
  return (
    <>
      <Button
        data-testid="botton-too-long"
        className={classes.feedbackButton}
        buttonSize="large"
        onClick={handleOnFeedbackPress(REASON_ENUM.TOO_LONG as SurveyFeedback)}
      >
        <Txt>{showReasonDetail(REASON_ENUM.TOO_LONG).description}</Txt>
      </Button>
      <Button
        data-testid="botton-stuck"
        className={classes.feedbackButton}
        buttonSize="large"
        onClick={handleOnFeedbackPress(REASON_ENUM.STUCK as SurveyFeedback)}
      >
        <Txt>{showReasonDetail(REASON_ENUM.STUCK).description}</Txt>
      </Button>
      <Button
        data-testid="botton-not-mobile-optimised"
        className={classes.feedbackButton}
        buttonSize="large"
        onClick={handleOnFeedbackPress(REASON_ENUM.NOT_MOBILE_OPTIMISED as SurveyFeedback)}
      >
        <Txt>{showReasonDetail(REASON_ENUM.NOT_MOBILE_OPTIMISED).description}</Txt>
      </Button>
      <Button
        data-testid="botton-not-mobile-compatible"
        className={classes.feedbackButton}
        buttonSize="large"
        onClick={handleOnFeedbackPress(REASON_ENUM.NOT_MOBILE_COMPATIBLE as SurveyFeedback)}
      >
        <Txt>{showReasonDetail(REASON_ENUM.NOT_MOBILE_COMPATIBLE).description}</Txt>
      </Button>
      <Button
        data-testid="button-wrong-language"
        className={classes.feedbackButton}
        buttonSize="large"
        onClick={handleOnFeedbackPress(REASON_ENUM.WRONG_LANGUAGE as SurveyFeedback)}
      >
        <Txt>{showReasonDetail(REASON_ENUM.WRONG_LANGUAGE).description}</Txt>
      </Button>
      <Button
        data-testid="botton-scam"
        className={classes.feedbackButton}
        buttonSize="large"
        onClick={handleOnFeedbackPress(REASON_ENUM.SCAM as SurveyFeedback)}
      >
        <Txt>{showReasonDetail(REASON_ENUM.SCAM).description}</Txt>
      </Button>
      <div className={classes.otherContainer}>
        <Input
          data-testid="input-other"
          className={showOtherButton()}
          placeholder={translate('Other...')}
          onChange={handleOtherChange}
          onFocus={handleOtherFocus}
        />
        <Button
          data-testid="botton-other"
          className={showOtherButton()}
          onClick={handleOnFeedbackPress(REASON_ENUM.CUSTOM as SurveyFeedback)}
        >
          <SendOtherIcon />
        </Button>
      </div>
    </>
  );
};

const SurveyFeedbackModalContent: React.FC<ContentProps> = ({ modalProps, onClose }) => {
  return (
    <div className={classes.container}>
      {modalProps.status === 'PENDING' ? (
        <>
          <Txt component="h2">
            It looks as if you&apos;re still doing a survey. Is there a problem with it? 🤨
          </Txt>
          <div className={classes.informationContainer}>
            <PropSurveyItem survey={modalProps} />
          </div>
          <Txt component="h3">Remember, you can only do one survey at a time.</Txt>
          <ul>
            <Txt component="li">
              If you have your original survey open, you can still complete it - just close this
              page.
            </Txt>
            <Txt component="li">
              If there&apos;s a reason you closed your original survey, or you have feedback about
              it, please let us know below!
            </Txt>
          </ul>
        </>
      ) : (
        <>
          <Txt component="h2">Was there a problem with this survey?</Txt>
          <div className={classes.informationContainer}>
            <PropSurveyItem survey={modalProps} />
          </div>
        </>
      )}
      <FeedbackButtons modalProps={modalProps} onClose={onClose} />
    </div>
  );
};

const SurveyFeedbackModal: React.FC<Props> = ({ defaultModalProps }) => {
  const { hideModal, modalProps } = useModalContext();
  const alert = useAlert();
  const thisModalProps = defaultModalProps || modalProps;
  const [sendSurveyAbandoned] =
    useMutation<SurveyFeedbackModalAbandonedMutation>(surveyAbandonedMutation);

  React.useEffect(() => {
    if (thisModalProps?.status === 'PENDING') {
      localStorage.setItem('lastPending', thisModalProps.id);
    }
  }, [thisModalProps]);

  const showCompleteMessage = (report: string) => {
    hideModal();
    const replyMessage = showReasonDetail(report).message;
    alert.show(replyMessage, {
      type: 'success',
      timeout: messageTimeout(replyMessage),
    });
  };

  const handleAbandonedClose = () => {
    sendSurveyAbandoned({
      variables: {
        input: {
          studyId: thisModalProps.studyId || 'missingFromSite',
        },
      },
    });
    hideModal();
  };
  return (
    <Overlay onClose={handleAbandonedClose} showClose>
      <SurveyFeedbackModalContent modalProps={thisModalProps} onClose={showCompleteMessage} />
    </Overlay>
  );
};

export default SurveyFeedbackModal;
