import clsx from 'clsx';
import React from 'react';
import classes from './Input.module.scss';

interface Props {
  className?: string;
}

const Input = React.forwardRef<HTMLInputElement, Props & React.HTMLProps<HTMLInputElement>>(
  function Input({ className, ...rest }, ref) {
    return <input ref={ref} className={clsx(classes.input, className)} type="text" {...rest} />;
  },
);
export default Input;
